import { Paragraph } from "./text/Paragraph";

type DateFormatterProps = {
  className?: string;
  date: Date;
};

const formatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export function DateFormatter({ className, date }: DateFormatterProps) {
  return (
    <Paragraph variant={"small"} className={className}>
      <time dateTime={date.toISOString()}>{formatter.format(date)}</time>
    </Paragraph>
  );
}

const monthFormatterLong = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
});

const monthFormatterShort = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
});

type MonthFormatterProps = DateFormatterProps & {
  format?: "short" | "long";
};

export function MonthFormatter({
  className,
  date,
  format = "long",
}: MonthFormatterProps) {
  return (
    <Paragraph variant={"small"}>
      <time dateTime={date.toISOString()} className={className}>
        {format === "long"
          ? monthFormatterLong.format(date)
          : monthFormatterShort.format(date)}
      </time>
    </Paragraph>
  );
}
const fullDateformatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export function FullDateFormatter({ className, date }: DateFormatterProps) {
  return (
    <Paragraph variant={"small"} className={className}>
      <time dateTime={date.toISOString()}>
        {fullDateformatter.format(date)}
      </time>
    </Paragraph>
  );
}
